import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    body: "Exo, sans-serif",
  },
  colors: {
    brand: {
      50: "#00b0ff21",
      100: "#00b0ff",
      200: "#00b0ff",
      300: "#00b0ff",
      400: "#00b0ff",
      500: "#00b0ff",
      600: "#00b0ff",
      700: "#00b0ff",
      800: "#00b0ff",
      900: "#00b0ff",
    },
  },
  // Modal: { sizes: { xl: { Content: { maxWidth: "90rem" }, }, }, }
});

export default theme;
